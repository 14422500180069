import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { UserSettings } from 'src/app/modules/users/models/userSettings.interface';
import {
  AttributeListType,
  AttributeType,
  ListUsersResponse,
  UserType,
} from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { params } from 'src/app/shared/helpers/aws-config';
import {
  Observable,
  from,
  throwError,
  BehaviorSubject,
  of,
  Subject,
} from 'rxjs';

import { switchMap, catchError, map, filter } from 'rxjs/operators';
import { isNotUndefined } from '../../helpers/isNotUndefined';

export interface AuthState {
  isLoggedIn: boolean;
}

const initialAuthState = {
  isLoggedIn: false,
};

interface AWSConfig {
  apiVersion: string;
  region: string;
}

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  public loggedIn = new Subject();
  // Used for watching the authorization state of the user
  private readonly _authState = new BehaviorSubject<AuthState>(
    initialAuthState
  );
  // Allow the state to be observable
  readonly auth$ = this._authState.asObservable();
  // Extract the logged in value from the authorization state
  readonly isLoggedIn$ = this.auth$.pipe(map((state) => state.isLoggedIn));

  awsConfig: AWSConfig;

  constructor() {
    this.awsConfig = {
      apiVersion: '2016-04-18',
      region: 'us-east-1',
    };

    // Determine if user is logged in
    Hub.listen('auth', ({ payload: { event, data, message } }) => {
      if (event === 'signIn') {
        this._authState.next({ isLoggedIn: true });
        this.loggedIn.next(true);
      } else {
        this._authState.next({ isLoggedIn: false });
        this.loggedIn.next(false);
      }
    });
  }

  // Methods to get user data
  // Returns information about the users credentials/tokens
  getUserCredentials(): Observable<any> {
    return from(Auth.currentCredentials());
  }

  isLoggedIn(): Observable<any> {
    return from(Auth.currentAuthenticatedUser());
  }

  getIdToken(): Observable<any> {
    from(Auth.currentUserCredentials()).subscribe((creds) =>
      console.log(creds)
    );
    return from(Auth.currentSession()).pipe(
      map((cognitoUserSession) => cognitoUserSession.getIdToken()),
      map((cognitoIdToken) => cognitoIdToken.getJwtToken())
    );
  }

  getCognito(): Observable<CognitoIdentityServiceProvider> {
    return this.getUserCredentials().pipe(
      switchMap((credentials) =>
        of(
          new CognitoIdentityServiceProvider({
            ...credentials,
            ...params,
            ...this.awsConfig,
          })
        )
      )
    );
  }

  getUserFullName(username: string | undefined | null): Observable<string> {
    if (!username) return of('');
    return this.getCognito().pipe(
      switchMap((cognito) => {
        return from(
          cognito
            .listUsers({
              ...params,
              Filter: `username = "${username}"`,
            })
            .promise()
        ).pipe(
          map((response) => response.$response.data),
          filter((response): response is ListUsersResponse =>
            isNotUndefined(response)
          ),
          map((response) => response.Users![0]),
          map((user: UserType) => {
            if (user.Attributes) {
              const FirstName = user.Attributes.find(
                (attribute: AttributeType) => {
                  return attribute.Name === 'given_name';
                }
              )?.Value;
              const LastName = user.Attributes.find(
                (attribute: AttributeType) => {
                  return attribute.Name === 'family_name';
                }
              )?.Value;
              return `${FirstName} ${LastName}`;
            }
            return '';
          })
        );
      })
    );
  }

  // Returns information on the cognito user
  getUser(): Observable<any> {
    return from(Auth.currentUserPoolUser());
  }

  getCurrentUserSettings(): Observable<UserSettings> {
    return from(Auth.currentAuthenticatedUser()).pipe(
      map((user) => user.attributes),
      map((attributes) => {
        return {
          FirstName: attributes.given_name,
          LastName: attributes.family_name,
          Email: attributes.email,
          OrganizationID: attributes['custom:organization_id'] || 1,
        };
      }),
      catchError(() => {
        return of({
          FirstName: 'Interview',
          LastName: 'Candidate',
          Email: 'SYSTEM',
        });
      })
    );
  }

  userIsVerified(): Observable<boolean> {
    return from(Auth.currentAuthenticatedUser()).pipe(
      map((user) => user.attributes),
      map((attributes) => attributes.email_verified)
    );
  }

  // Methods to change user settings

  sendVerificationEmail(): Observable<any> {
    return from(Auth.verifyCurrentUserAttribute('email'));
  }

  verifyEmail(code: string): Observable<any> {
    return from(Auth.verifyCurrentUserAttributeSubmit('email', code)).pipe(
      switchMap((changeEmail) => from(Auth.currentAuthenticatedUser())),
      switchMap((user) =>
        from(Auth.updateUserAttributes(user, { email_verified: true }))
      ),
      catchError((err) => throwError(err))
    );
  }

  updateCurrentUserSettings(userSettings: UserSettings): Observable<any> {
    return from(Auth.currentAuthenticatedUser()).pipe(
      switchMap((user) =>
        Auth.updateUserAttributes(user, {
          given_name: userSettings.FirstName,
          family_name: userSettings.LastName,
          email: userSettings.Email,
        })
      )
    );
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    return from(Auth.currentAuthenticatedUser()).pipe(
      switchMap((user) => Auth.changePassword(user, oldPassword, newPassword))
    );
  }

  // user actions

  signOut(): Observable<any> {
    return from(Auth.signOut());
  }
}
