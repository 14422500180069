import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  Validators,
  FormArray,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarHandlerService } from 'src/app/shared/services/snackbar-handler.service';
import { QuestionsBuilderService } from '../../questions-builder.service';
import { mergeMap, tap, catchError } from 'rxjs/operators';
import {
  combineLatest,
  Observable,
  Subscription,
  forkJoin,
  of,
  throwError,
} from 'rxjs';
import { QuestionDialogData } from '../questions-modal/questions-modal.component';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import {
  Actions,
  ConfirmActionService,
  UpdatingRecordTypes,
} from 'src/app/shared/services/confirm-action.service';
import {
  faAngleDown,
  faAngleUp,
  faFileDownload,
  faPlusCircle,
  faTimesCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { DownloadHandlerService } from 'src/app/shared/services/download-handler.service';
import { MonacoEditorService } from 'src/app/shared/services/monaco/monaco-editor.service';
import { NotificationsManagerService } from 'src/app/modules/notifications-manager/notifications-manager.service';
import { CognitoService } from 'src/app/shared/services/cognito/cognito.service';
import { AssessmentsBuilderService } from 'src/app/modules/assessments-builder/assessments-builder.service';
import { InvitationsBuilderService } from 'src/app/modules/invitations-builder/invitations-builder.service';

import {
  QuestionAttachment,
  QuestionType,
  Question,
  QuestionOption,
  NotificationEventData,
  Invitation,
  Assessment,
  Language,
} from 'src/app/shared/models/models.index';
import { Notifications } from 'src/app/shared/constants.enum';

@Component({
  selector: 'app-questions-form',
  templateUrl: './questions-form.component.html',
  styleUrls: ['./questions-form.component.css'],
})
export class QuestionsFormComponent implements OnInit, OnDestroy {
  @Input() data: QuestionDialogData | null = null;
  @Output() closeModal = new EventEmitter<boolean>();
  maxAnswerLen: number = 10000;
  maxAttachmentsAllowed: number = 2;
  maxAttachmentSizeInBytes: number = 1000000;
  minPointsAllowed: number = 0;
  maxPointsAllowed: number = 99;
  user: any | null = null;
  questionsForm = this.fb.group({
    name: ['', Validators.required],
    description: ['', Validators.required],
    questionFiles: this.fb.array([this.fb.control('')]),
    questionType: new FormControl<QuestionType>(
      {} as QuestionType,
      Validators.required
    ),
    plainText: this.fb.group({
      optionId: null,
      plainTextAnswer: '',
      points: [''],
    }),
    questionText: '',
    availableLanguages: new FormControl<any[]>([]),
    defaultLanguage: new FormControl<Language>(
      {} as Language,
      Validators.required
    ),
    editorRunner: this.fb.group({
      codeSnippets: this.fb.array([]),
      points: [''],
    }),
    multiOptions: this.fb.array([]),
  });
  subscription: Subscription = new Subscription();
  questionTypes: QuestionType[] = [];
  selectedQuestionType: QuestionType = {
    QuestionTypeName: '',
    QuestionTypeDescription: null,
  };
  questionAttachments: QuestionAttachment[] = [];
  allLanguages: any[] = [];
  availableLanguages: any[] = [];
  runnableLanguages: any[] = [];
  defaultLanguage: any = {};
  isSaving: boolean = false;
  monaco: any; // monaco namespace
  monacoEditor: any; // monaco editor instance
  readOnly: boolean = false;
  dataChanged: boolean = false;
  fileReader: FileReader = new FileReader();
  editorOptions = {
    theme: 'vs-dark',
    language: '',
    minimap: { enabled: false },
  };
  trashIcon = faTrash;
  downloadIcon = faFileDownload;
  timesCircleIcon = faTimesCircle;
  plusCircleIcon = faPlusCircle;
  angleUpIcon = faAngleUp;
  angleDownIcon = faAngleDown;
  editedByUserFullName = '';
  waiting = false;

  constructor(
    private fb: FormBuilder,
    private questionsBuilderService: QuestionsBuilderService,
    private router: Router,
    private route: ActivatedRoute,
    private monacoEditorService: MonacoEditorService,
    private snackBarHandlerService: SnackbarHandlerService,
    private downloadHandlerService: DownloadHandlerService,
    public confirmationDialog: MatDialog,
    public confirmActionService: ConfirmActionService,
    private notificationsManagerService: NotificationsManagerService,
    private cognitoService: CognitoService,
    private assessmentsBuilderService: AssessmentsBuilderService,
    private invitationsBuilderService: InvitationsBuilderService
  ) {}

  get codeSnippets() {
    const fg = this.questionsForm.controls.editorRunner as FormGroup;
    return fg.get('codeSnippets') as FormArray;
  }

  get plainText() {
    return this.questionsForm.controls.plainText as FormGroup;
  }

  get questionFiles() {
    return this.questionsForm.get('questionFiles') as FormArray;
  }

  get multiOptions() {
    return this.questionsForm.get('multiOptions') as FormArray;
  }

  get isCodeRunner() {
    return this.selectedQuestionType.QuestionTypeName === 'Code Runner'
      ? true
      : false;
  }
  get isCodeEditor() {
    return this.selectedQuestionType.QuestionTypeName === 'Code Editor'
      ? true
      : false;
  }
  get isPlainText() {
    return this.selectedQuestionType.QuestionTypeName === 'Plain Text'
      ? true
      : false;
  }
  get isMultiSelect() {
    return this.selectedQuestionType.QuestionTypeName === 'Multi Select'
      ? true
      : false;
  }
  get isMultiChoice() {
    return this.selectedQuestionType.QuestionTypeName === 'Multi Choice'
      ? true
      : false;
  }

  clearDefaultLanguage(): Language {
    return {
      id: '',
      extensions: [],
    };
  }

  ngOnInit(): void {
    this.getUser();

    if (!!this.data && this.data.action !== 'create') {
      this.getEditedByUserFullName();
    }

    this.readOnly =
      this.data?.action === 'view' || this.data?.action === 'delete';
    this.subscription.add(
      this.monacoEditorService
        .getMonaco()
        .pipe(
          tap((monaco) => (this.monaco = monaco)),
          mergeMap(() =>
            combineLatest([
              this.monacoEditorService.getMonacoLanguages(),
              this.questionsBuilderService.getRunnableLanguages(),
              this.questionsBuilderService.getQuestionTypes(),
            ])
          ),
          tap(([monacoLangs, runnableLangs, questionTypes]) => {
            this.runnableLanguages = runnableLangs;
            this.questionTypes = questionTypes;
            if (
              (this.data?.action === 'create' &&
                this.questionsForm.controls.questionType.value!
                  .QuestionTypeName === 'Code Editor') ||
              this.selectedQuestionType.QuestionTypeName === 'Code Editor'
            ) {
              this.allLanguages = monacoLangs.filter(
                (lang) =>
                  !this.runnableLanguages.find(
                    (runnable) => runnable.name === lang.id
                  )
              );
            } else {
              this.allLanguages = monacoLangs.filter((lang) =>
                this.runnableLanguages.find(
                  (runnable: any) => runnable.name === lang.id
                )
              );
            }
            if (this.data?.action !== 'create') {
              this.populateStaticFields();
            }
          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.questionsForm.controls.availableLanguages.valueChanges.subscribe(
        (availableLanguages) => {
          this.availableLanguages = availableLanguages
            ? availableLanguages
            : [];
          this.updateCodeSnippets();
          if (
            this.availableLanguages &&
            !this.availableLanguages.find(
              (lang) => lang.id === this.defaultLanguage.id
            )
          ) {
            this.questionsForm.controls.defaultLanguage.setValue(
              this.clearDefaultLanguage()
            );
          }
        }
      )
    );

    this.subscription.add(
      this.questionsForm.controls.defaultLanguage.valueChanges.subscribe(
        (defaultLanguage) => {
          this.defaultLanguage = defaultLanguage;
        }
      )
    );

    this.subscription.add(
      this.questionsForm.controls.questionType.valueChanges.subscribe(
        (questionType) => {
          if (questionType) {
            const _questionType = questionType as unknown as QuestionType;
            this.selectedQuestionType = _questionType;
            this.resetOptionsFields(_questionType.QuestionTypeName);
            if (this.data?.action !== 'create')
              this.populateOptions(_questionType.QuestionTypeName);
          }
        }
      )
    );

    if (this.readOnly) this.questionsForm.disable();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  resetOptionsFields(questionTypeName: string): void {
    if (
      questionTypeName === 'Code Editor' ||
      questionTypeName === 'Code Runner'
    ) {
      this.questionsForm.controls.availableLanguages.setValidators(
        Validators.required
      );
      this.questionsForm.controls.defaultLanguage.setValidators(
        Validators.required
      );
      this.questionsForm.controls.availableLanguages.setValue([]);
    } else {
      this.questionsForm.controls.availableLanguages.setErrors(null);
      this.questionsForm.controls.defaultLanguage.setErrors(null);
      this.questionsForm.controls.availableLanguages.setValidators([]);
      this.questionsForm.controls.defaultLanguage.setValidators([]);
    }
    this.questionsForm.controls.availableLanguages.updateValueAndValidity();
    this.questionsForm.controls.defaultLanguage.updateValueAndValidity();

    if (
      questionTypeName === 'Multi Choice' ||
      questionTypeName === 'Multi Select'
    ) {
      this.multiOptions.clear();
      if (this.data?.action === 'create') this.addMultiOption();
    } else if (questionTypeName === 'Plain Text') {
      this.plainText.setValue({
        optionId: null,
        plainTextAnswer: 'Write your hint here....',
        points: 0,
      });
    }
  }

  populateStaticFields(): void {
    const _question = this.data?.question as unknown as Question;
    this.questionsForm.controls.questionType.setValue(_question.QuestionType);
    this.questionsForm.controls.name.setValue(_question.QuestionName);
    this.questionsForm.controls.description.setValue(
      _question.QuestionDescription
    );
    this.questionAttachments = this.data?.question.QuestionAttachments || [];
    // cannot set file input type programmatically, so cannot update formcontrol value here
    this.questionAttachments.forEach(() => {
      if (this.questionFiles.length < this.maxAttachmentsAllowed) {
        this.questionFiles.push(this.fb.control(''));
      }
    });
  }

  populateOptions(questionTypeName: string): void {
    if (questionTypeName === 'Plain Text') {
      this.plainText.setValue({
        optionId: this.data?.question.QuestionOptions[0].QuestionOptionID,
        plainTextAnswer:
          this.data?.question.QuestionType.QuestionTypeName === questionTypeName
            ? this.data?.question.QuestionOptions[0].QuestionOptionChoice
            : '',
        points: this.data?.question.QuestionOptions[0].QuestionOptionPoints,
      });
    } else if (
      questionTypeName === 'Code Editor' ||
      questionTypeName === 'Code Runner'
    ) {
      if (
        this.data?.question.QuestionType.QuestionTypeName === questionTypeName
      ) {
        const availableLanguages: Language[] = [];
        let defaultLanguage: Language = this.clearDefaultLanguage();
        this.data?.question.QuestionOptions.forEach((option) => {
          const langObject = this.allLanguages.find(
            (lang) => lang.id === option.QuestionOptionLanguage
          );

          if (langObject) {
            availableLanguages.push(langObject);
            this.codeSnippets.push(
              this.fb.group({
                optionId: option.QuestionOptionID,
                languageAlias: langObject.aliases![0],
                languageId: langObject.id,
                code: option.QuestionOptionChoice,
              })
            );
            if (option.QuestionOptionIsDefault) {
              defaultLanguage = langObject;
              this.questionsForm.controls.editorRunner.patchValue({
                points: option.QuestionOptionPoints.toString(),
              });
            }
          }
        });
        this.questionsForm.controls.availableLanguages.setValue(
          availableLanguages
        );
        this.questionsForm.controls.defaultLanguage.setValue(defaultLanguage);
        this.updateCodeSnippets();
      }
    } else if (
      questionTypeName === 'Multi Choice' ||
      questionTypeName === 'Multi Select'
    ) {
      if (
        this.data?.question.QuestionType.QuestionTypeName === questionTypeName
      ) {
        this.data?.question.QuestionOptions.forEach((option) =>
          this.addMultiOption(option)
        );
      } else {
        this.addMultiOption();
      }
    }
  }

  onSelectFile(e: Event, idx: number): void {
    const element = e.target as HTMLInputElement;
    if (element.files && element.files[0]) {
      const file = element.files[0];
      if (file.size > this.maxAttachmentSizeInBytes) {
        this.snackBarHandlerService.openSnackBar(
          `File size exceeds max of ${
            this.maxAttachmentSizeInBytes / 1000000
          } MBs.`
        );
        return;
      } else {
        const newAttachment: QuestionAttachment = {
          QuestionAttachmentName: file.name,
          QuestionAttachmentMIME: file.type,
          QuestionAttachmentSizeBytes: file.size,
          QuestionAttachmentExtension: file.name.split('.')[1],
          Base64String: '',
        };
        this.fileReader.readAsDataURL(file);
        this.fileReader.onload = () => {
          if (this.fileReader.result) {
            const dataURL = this.fileReader.result.toString();
            newAttachment.Base64String = dataURL.replace(
              /^data:.*\/.*;base64,/,
              ''
            );
          }
        };
        this.questionAttachments[idx] = newAttachment;
        if (this.questionFiles.length < this.maxAttachmentsAllowed) {
          this.questionFiles.push(this.fb.control(''));
        }
      }
    }
  }

  onRemoveFile(idx: number): void {
    this.questionAttachments.splice(idx, 1);
    if (this.questionFiles.length > 1 && this.questionAttachments.length < 1) {
      this.questionFiles.removeAt(idx);
      this.questionFiles.markAsDirty();
    }
  }

  onDownloadFile(idx: number): void {
    if (this.data?.question && this.questionAttachments[idx]) {
      this.subscription.add(
        this.questionsBuilderService
          .downloadQuestionAttachment(
            this.data?.question.QuestionID!,
            this.questionAttachments[idx].QuestionAttachmentID!
          )
          .subscribe((questionAttachment) => {
            this.downloadHandlerService.downloadFileFrom64(
              questionAttachment.Base64String!,
              questionAttachment.QuestionAttachmentMIME!,
              questionAttachment.QuestionAttachmentName!
            );
          })
      );
    }
  }

  addMultiOption(option?: QuestionOption): void {
    if (option) {
      const fg = this.fb.group({
        optionId: option.QuestionOptionID,
        answer: [option.QuestionOptionChoice, Validators.required],
        points: [
          option.QuestionOptionPoints,
          [
            Validators.min(this.minPointsAllowed),
            Validators.max(this.maxPointsAllowed),
          ],
        ],
      });
      if (this.readOnly) fg.disable();
      this.multiOptions.push(fg);
    } else {
      const fg = this.fb.group({
        optionId: null,
        answer: ['', Validators.required],
        points: [
          '',
          [
            Validators.min(this.minPointsAllowed),
            Validators.max(this.maxPointsAllowed),
          ],
        ],
      });
      if (this.readOnly) fg.disable();
      this.multiOptions.push(fg);
    }
  }

  removeMultiOption(idx: number): void {
    if (!this.readOnly && idx >= 0 && this.multiOptions.length > 1) {
      this.multiOptions.removeAt(idx);
      this.questionsForm.markAsDirty();
    }
  }

  moveMultiOption(direction: 'up' | 'down', idx: number): void {
    if (!this.readOnly) {
      if (direction === 'up' && idx > 0) {
        const curr = this.multiOptions.at(idx);
        const prev = this.multiOptions.at(idx - 1);
        this.multiOptions.setControl(idx, prev);
        this.multiOptions.setControl(idx - 1, curr);
        this.questionsForm.markAsDirty();
      } else if (direction === 'down' && idx < this.multiOptions.length - 1) {
        const curr = this.multiOptions.at(idx);
        const next = this.multiOptions.at(idx + 1);
        this.multiOptions.setControl(idx, next);
        this.multiOptions.setControl(idx + 1, curr);
        this.questionsForm.markAsDirty();
      }
    }
  }

  updateCodeSnippets(): void {
    if (this.availableLanguages.length < this.codeSnippets.controls.length) {
      this.codeSnippets.controls.forEach((snippetControl, idx) => {
        if (
          !this.availableLanguages.find(
            (lang) => lang.id === snippetControl.value.languageId
          )
        ) {
          this.codeSnippets.removeAt(idx);
        }
      });
    } else {
      this.availableLanguages.forEach((lang, idx) => {
        if (
          !this.codeSnippets.controls.find(
            (snippetControl) => snippetControl.value.languageId === lang.id
          )
        ) {
          this.codeSnippets.insert(
            idx,
            this.fb.group({
              optionId: undefined,
              languageAlias: lang.aliases[0],
              languageId: lang.id,
              code: '',
              points: [
                '',
                [
                  Validators.min(this.minPointsAllowed),
                  Validators.max(this.maxPointsAllowed),
                ],
              ],
            })
          );
        }
      });
    }
  }

  onEditorInit(editor: any, idx: number): void {
    this.monacoEditor = editor;
    this.monaco.editor.setModelLanguage(
      this.monacoEditor.getModel(),
      this.codeSnippets.controls[idx].value.languageId
    );
    this.monacoEditor.updateOptions({ readOnly: this.readOnly });
    this.monacoEditor.getModel().onDidChangeContent(() => {
      if (
        this.codeSnippets.controls[idx].value.code.length > this.maxAnswerLen
      ) {
        this.monacoEditor.getModel().undo();
      }
    });
  }

  handleMonacoFormValidation(): void {
    // disregarding monaco's code errors for form validation
    if (
      this.selectedQuestionType.QuestionTypeName === 'Code Runner' ||
      this.selectedQuestionType.QuestionTypeName === 'Code Editor'
    ) {
      for (let i = 0; i < this.codeSnippets.controls.length; i++) {
        // these are FormGroups
        const controls = this.codeSnippets.controls as any;
        if (controls[i].value.code.length <= this.maxAnswerLen) {
          controls[i].controls.code.setErrors(null);
          controls[i].updateValueAndValidity();
        }
      }
    }
  }

  buildQuestionOptions(): QuestionOption[] {
    let options: QuestionOption[] = [];
    if (
      this.selectedQuestionType.QuestionTypeName === 'Code Editor' ||
      this.selectedQuestionType.QuestionTypeName === 'Code Runner'
    ) {
      options = this.codeSnippets.controls.map((snippetControl) => {
        const option: QuestionOption = {
          QuestionOptionID: snippetControl.value.optionId,
          QuestionOptionChoice: snippetControl.value.code,
          QuestionOptionIsDefault:
            snippetControl.value.languageId === this.defaultLanguage.id,
          QuestionOptionLanguage: snippetControl.value.languageId,
          QuestionOptionPosition: null,
          QuestionOptionPoints:
            Number(this.questionsForm.controls.editorRunner.value!.points) || 0,
        };
        return option;
      });
    } else if (this.selectedQuestionType.QuestionTypeName === 'Plain Text') {
      const option: QuestionOption = {
        QuestionOptionID: this.plainText.value.optionId,
        QuestionOptionChoice: this.plainText.value.plainTextAnswer,
        QuestionOptionIsDefault: null,
        QuestionOptionLanguage: null,
        QuestionOptionPosition: null,
        QuestionOptionPoints: this.plainText.value.points || 0,
      };
      options.push(option);
    } else if (
      this.selectedQuestionType.QuestionTypeName === 'Multi Choice' ||
      this.selectedQuestionType.QuestionTypeName === 'Multi Select'
    ) {
      options = this.multiOptions.controls.map((multiOption, idx) => {
        const option: QuestionOption = {
          QuestionOptionID: multiOption.value.optionId,
          QuestionOptionChoice: multiOption.value.answer,
          QuestionOptionIsDefault: null,
          QuestionOptionLanguage: null,
          QuestionOptionPosition: idx,
          QuestionOptionPoints: multiOption.value.points || 0,
        };
        return option;
      });
    }
    return options;
  }

  isNameOrDescriptionEmpty() {
    return (
      !!!this.questionsForm.value?.description ||
      !!!this.questionsForm.value?.name
    );
  }

  onSubmit(editMode?: 'replace' | 'new'): void {
    this.waiting = true;
    this.handleMonacoFormValidation();
    const options = this.buildQuestionOptions();

    if (
      this.data?.action !== 'delete' &&
      (options.length === 0 ||
        this.isNameOrDescriptionEmpty() ||
        ((this.isMultiChoice || this.isMultiSelect) &&
          this.multiOptions.controls.some((multiOption) => {
            return multiOption.value.answer === '';
          })) ||
        ((this.isCodeRunner || this.isCodeEditor) &&
          this.defaultLanguage.id === ''))
    ) {
      this.waiting = false;
      this.snackBarHandlerService.openSnackBar('Missing required field(s)');
      return;
    }

    this.isSaving = true;
    const newQuestion: Question = {
      QuestionName: this.questionsForm.value?.name!,
      QuestionDescription: this.questionsForm.value?.description!,
      QuestionHint: null,
      QuestionType: this.selectedQuestionType,
      QuestionOptions: options,
      QuestionEstimatedDurationMinutes: null,
      QuestionIsHardStop: null,
      QuestionTimeLimitMinutes: null,
      OrganizationID: 1,
    };

    if (this.questionAttachments.length > 0) {
      newQuestion.QuestionAttachments = this.questionAttachments;
    }

    if (this.data?.action === 'create') {
      this.questionsBuilderService
        .saveQuestion(newQuestion)
        .pipe(
          catchError((err) => {
            this.waiting = false;
            return throwError(err);
          })
        )
        .subscribe(() => {
          this.dataChanged = true;
          this.waiting = false;
          this.snackBarHandlerService.openSnackBar('Question Saved!');
          this.onExit();
        });
    } else if (this.data?.action === 'edit') {
      this.dataChanged = true;
      newQuestion.OrganizationID = this.data?.question.OrganizationID;
      newQuestion.QuestionAttachments?.forEach(
        (qa) => (qa.QuestionID = newQuestion.QuestionID)
      );

      // Remove questionOptionID from the options to allow them to be created fresh
      newQuestion.QuestionOptions.forEach((options) => {
        delete options.QuestionOptionID;
      });

      if (editMode === 'replace') {
        newQuestion.QuestionID = this.data?.question.QuestionID;
        // Save and Replace
        this.subscription.add(
          this.confirmActionService
            .getConfirmation(
              UpdatingRecordTypes.QUESTION,
              Actions.EDIT,
              this.data?.question.QuestionID!
            )
            .subscribe((confirmChange: boolean) => {
              if (confirmChange) {
                const notificationEventData: NotificationEventData = {
                  id: this.data?.question.QuestionID,
                  user: this.user?.given_name + ' ' + this.user?.family_name,
                };
                this.questionsBuilderService
                  .updateQuestion(this.data?.question.QuestionID!, newQuestion)
                  .pipe(
                    catchError((err) => {
                      this.waiting = false;
                      return throwError(err);
                    })
                  )
                  .subscribe((savedQuestion) => {
                    combineLatest([
                      this.assessmentsBuilderService.getAssessmentsByQuestionID(
                        {
                          questionID: this.data?.question.QuestionID!,
                          includeInvalid: true,
                        }
                      ),
                      this.assessmentsBuilderService.getAssessmentsResponsesByQuestionID(
                        {
                          questionID: this.data?.question.QuestionID!,
                          includeResponses: true,
                          includeLinkedResponses: true,
                        }
                      ),
                      // Notify creator of question that its been updated
                      this.notificationsManagerService.triggerNotificationEvent(
                        Notifications.AuthoredQuestionUpdated,
                        this.data?.question.CreatedByUser!,
                        notificationEventData,
                        this.data?.question.OrganizationID!
                      ),
                    ])
                      .pipe(
                        mergeMap(([assessments, assessmentResponses, _]) => {
                          if (assessments.length !== 0) {
                            let newAssessmentCreations: any = [];
                            (<any>assessments).forEach((assessment: any) => {
                              let today = new Date().toLocaleDateString();

                              // If the fetched assessments do not have rotations assigned to them we need to set
                              // to an empty array or the request to the api will error out
                              assessment.Rotations = assessment.Rotations || [];

                              // If the assessment containing the current question is linked to a
                              // current response, create a new assessment
                              if (
                                assessmentResponses.some((obj: Assessment) => {
                                  return (
                                    obj.AssessmentID === assessment.AssessmentID
                                  );
                                })
                              ) {
                                assessment.AssessmentName +=
                                  ' (rev. ' + today + ')';
                                // Remove previous question from new assessments list
                                const index = assessment.QuestionIDs.indexOf(
                                  this.data?.question.QuestionID!
                                );

                                if (index !== -1) {
                                  assessment.QuestionIDs.splice(
                                    index,
                                    1,
                                    savedQuestion.QuestionID
                                  );
                                }

                                newAssessmentCreations.push(
                                  this.assessmentsBuilderService.saveAssessment(
                                    assessment
                                  )
                                );
                              }
                            });

                            if (newAssessmentCreations.length !== 0) {
                              return forkJoin(newAssessmentCreations);
                            }
                          }
                          return of(null);
                        }),
                        catchError((err) => {
                          this.waiting = false;
                          return throwError(err);
                        })
                      )
                      .subscribe(() => {
                        this.snackBarHandlerService.openSnackBar(
                          'Question Updated!'
                        );
                        this.onExit();
                      });
                  });
              }
            })
        );
      } else {
        // Save as New
        const notificationEventData: NotificationEventData = {
          id: this.data?.question.QuestionID,
          user: this.user?.given_name + ' ' + this.user?.family_name,
        };

        combineLatest([
          this.questionsBuilderService.saveQuestion(newQuestion),
          this.notificationsManagerService.triggerNotificationEvent(
            Notifications.AuthoredQuestionNewVersion,
            this.data?.question.CreatedByUser!,
            notificationEventData,
            this.data?.question.OrganizationID!
          ),
        ]).subscribe(() => {
          this.snackBarHandlerService.openSnackBar('New Question Created!');
          this.onExit();
        });
      }
    } else if (this.data?.action === 'delete') {
      this.dataChanged = true;
      this.subscription.add(
        this.confirmActionService
          .getConfirmation(
            UpdatingRecordTypes.QUESTION,
            Actions.DELETE,
            this.data?.question.QuestionID!
          )
          .subscribe((confirmChange: boolean) => {
            if (confirmChange) {
              const notificationEventData: NotificationEventData = {
                id: this.data?.question.QuestionID,
                user: this.user?.given_name + ' ' + this.user?.family_name,
              };
              combineLatest([
                this.questionsBuilderService.deleteQuestion(
                  this.data?.question.QuestionID || 0
                ),
                this.notificationsManagerService.triggerNotificationEvent(
                  Notifications.AuthoredQuestionDeleted,
                  this.data?.question.CreatedByUser!,
                  notificationEventData,
                  this.data?.question.OrganizationID!
                ),
              ])
                .pipe(
                  mergeMap((_) => {
                    return this.assessmentsBuilderService.getAssessmentsByQuestionID(
                      {
                        questionID: this.data?.question.QuestionID!,
                        includeInvalid: true,
                      }
                    );
                  }),
                  mergeMap((assessments) => {
                    if (assessments.length !== 0) {
                      return forkJoin([
                        this.getInvitations(assessments),
                        this.sendAssessmentInvalidationNotifications(
                          assessments
                        ),
                      ]);
                    } else {
                      return of(null);
                    }
                  }),
                  mergeMap((invitations, _) => {
                    if (invitations !== null) {
                      return this.invitationInvalidationNotifications(
                        invitations
                      );
                    } else {
                      return of(null);
                    }
                  }),
                  catchError((err) => {
                    this.waiting = false;
                    return throwError(err);
                  })
                )
                .subscribe(() => {
                  this.snackBarHandlerService.openSnackBar('Question Deleted!');
                  this.onExit();
                });
            } else {
              this.waiting = false;
            }
          })
      );
    } else if (this.data?.action === 'undelete') {
      this.dataChanged = true;
      this.subscription.add(
        this.confirmActionService
          .getConfirmation(
            UpdatingRecordTypes.QUESTION,
            Actions.UNDELETE,
            this.data?.question.QuestionID!
          )
          .subscribe((confirmChange: boolean) => {
            if (confirmChange) {
              const notificationEventData: NotificationEventData = {
                id: this.data?.question.QuestionID,
                user: this.user?.given_name + ' ' + this.user?.family_name,
              };
              combineLatest([
                this.questionsBuilderService.unarchiveQuestion(
                  this.data?.question.QuestionID!,
                  this.data?.question!
                ),
                this.notificationsManagerService.triggerNotificationEvent(
                  Notifications.AuthoredQuestionUnarchived,
                  this.data?.question.CreatedByUser!,
                  notificationEventData,
                  this.data?.question.OrganizationID!
                ),
              ])
                .pipe(
                  catchError((err) => {
                    this.waiting = false;
                    return throwError(err);
                  })
                )
                .subscribe(() => {
                  this.snackBarHandlerService.openSnackBar(
                    'Question Unarchived!'
                  );
                  this.onExit();
                });
            } else {
              this.waiting = false;
            }
          })
      );
    }
  }

  // Trigger events for all invalidated invitations from deleting a question
  private invitationInvalidationNotifications(
    invitations: Invitation[][]
  ): Observable<any[]> {
    let invitationInvalidationNotifications: any = [];
    invitations.forEach((invitation) => {
      if (!invitation[0].IsValid) {
        invitationInvalidationNotifications.push(
          this.notificationsManagerService.triggerNotificationEvent(
            Notifications.AuthoredInviteInvalidated,
            invitation[0].CreatedByUser!,
            { id: invitation[0].InvitationID },
            invitation[0].OrganizationID!
          )
        );
      }
    });
    return forkJoin(
      invitationInvalidationNotifications
    ) as unknown as Observable<any[]>;
  }

  // Get all invitations for the array of Assessments so we can determine which invitations are now invalidated
  private getInvitations(assessments: Assessment[]): Observable<any[]> {
    let invitationBatch: any = [];
    assessments.forEach((assessment) => {
      if (!assessment.IsValid) {
        invitationBatch.push(
          this.invitationsBuilderService.getInvitationsByAssessmentID({
            assessmentID: assessment.AssessmentID!,
          })
        );
      }
    });
    return forkJoin(invitationBatch) as unknown as Observable<any[]>;
  }

  private sendAssessmentInvalidationNotifications(
    assessments: Assessment[]
  ): Observable<any[]> {
    // Trigger notification for author of each assessment that has been invalidated
    let assessmentInvalidationNotifications: any = [];
    // Add values that we want to substitute in the pre-configured Notification in the event data.
    // In this case we want to replace (ID) with the question id
    assessments.forEach((assessment) => {
      if (!assessment.IsValid) {
        assessmentInvalidationNotifications.push(
          this.notificationsManagerService.triggerNotificationEvent(
            Notifications.AuthoredAssessmentInvalidated,
            assessment.CreatedByUser!,
            { id: assessment.AssessmentID },
            assessment.OrganizationID!
          )
        );
      }
    });
    return forkJoin(
      assessmentInvalidationNotifications
    ) as unknown as Observable<any[]>;
  }

  // Get the user id of the current user to set as the assigner. This will be used when updating the review when it is assigned
  getUser(): void {
    this.subscription.add(
      this.cognitoService.getUser().subscribe((currentUser) => {
        this.user = currentUser.attributes;
      })
    );
  }

  /* Resolves the edited by user's full name and places it in a variable for rendering */
  getEditedByUserFullName(): void {
    const editedByUser = this.data?.question.EditedByUser;
    this.subscription.add(
      this.cognitoService
        .getUserFullName(editedByUser)
        .subscribe(
          (fullname: any) =>
            (this.editedByUserFullName = fullname || editedByUser)
        )
    );
  }

  onExit(): void {
    if (!this.dataChanged && this.questionsForm.dirty) {
      const data: ConfirmationDialogData = {
        message: 'Leave with unsaved changes?',
      };
      const dialogRef = this.confirmationDialog.open(
        ConfirmationDialogComponent,
        {
          width: '300px',
          data,
        }
      );
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        confirmed && this.data?.action === 'create'
          ? this.router.navigate(['../'], { relativeTo: this.route })
          : confirmed
          ? this.closeModal.emit(this.dataChanged)
          : null;
      });
    } else {
      this.data?.action === 'create'
        ? this.router.navigate(['../'], { relativeTo: this.route })
        : this.closeModal.emit(this.dataChanged);
    }
    this.isSaving = false;
  }

  compareQuestionTypes(type1: QuestionType, type2: QuestionType): boolean {
    return type1 && type2
      ? type1.QuestionTypeName === type2.QuestionTypeName
      : type1 === type2;
  }

  compareLanguages(lang1: any, lang2: any): boolean {
    return lang1 && lang2 ? lang1.id === lang2.id : lang1 === lang2;
  }
}
