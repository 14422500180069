import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { RouteService } from '../shared/services/route/route.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private routeService: RouteService) {}

  /**
   * This function evalutes whether a user is authorized to access a particular route.
   * Currently, every route is restricted, only allowing logged in users,
   * with the exception of '/error', 'take-assessment/', and 'login/' which require
   * no authorization
   * */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise((resolve) => {
      /**
       * This is important! This prevents an infinite loop of
       * redirecting to the login page
       * */
      if (state.url === '/login' || state.url === '/signup') {
        resolve(true);
      }

      /* Check if user is logged in */
      Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then((user) => {
          if (user) {
            resolve(true);
          }
        })
        .catch(() => {
          if (!(state.url === '/login' || state.url === '/signup')) {
            this.routeService.redirectUrl = state.url;
            this.router.navigate(['/login']);
          }
          resolve(false);
        });
    });
  }
}
