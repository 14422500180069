import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DownloadHandlerService {
  private convertBase64ToBlob(base64: string, type: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type });
    return blob;
  }

  downloadFileFrom64(base64: string, type: string, fileName: string): void {
    const blob = this.convertBase64ToBlob(base64, type);
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
  }
}
