import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateQuestionComponent } from './pages/create-question/create-question.component';
import { ViewQuestionsComponent } from './pages/view-questions/view-questions.component';

const routes: Routes = [
  {
    path: '',
    component: ViewQuestionsComponent,
    data: { title: 'Questions' },
  },
  {
    path: 'create',
    component: CreateQuestionComponent,
    data: { title: 'Create Question' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuestionsBuilderRoutingModule {}
