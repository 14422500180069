import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SideNavService {
  private readonly isOpen = new BehaviorSubject<boolean>(true);
  public readonly isOpen$ = this.isOpen.asObservable();

  getIsOpen(): Boolean {
    return this.isOpen.getValue();
  }

  toggleIsOpen(): void {
    this.isOpen.next(!this.isOpen.getValue());
  }

  setIsOpen(newState: boolean): void {
    this.isOpen.next(newState);
  }
}
