import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { NotificationEvent } from 'src/app/shared/models/models.index';
import { CognitoService } from 'src/app/shared/services/cognito/cognito.service';
import { NotificationsManagerService } from '../../notifications-manager.service';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.css'],
})
export class NotificationsListComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() maxNotificationsShown: number = 100;
  isModal: boolean = false;
  subscription = new Subscription();
  notificationEvents: NotificationEvent[] = [];
  timesSinceNotificationCreations: string[] = [];
  displayedNotificationEventData: string[] = [
    'message',
    'timeSinceCreation',
    'markAsRead',
  ];
  checkIcon = faCheck;
  waiting: boolean = false;
  selection = new SelectionModel<NotificationEvent>(true, []);

  constructor(
    private notificationsManagerService: NotificationsManagerService,
    private cognitoService: CognitoService
  ) {}

  ngOnInit(): void {
    this.waiting = true;
    this.getNotificationEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('maxNotificationsShown')) {
      this.isModal = true;
    } else this.isModal = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getNotificationEvents(): void {
    this.subscription.add(
      this.cognitoService
        .getUser()
        .pipe(
          mergeMap((userCreds) =>
            this.notificationsManagerService.getNotificationEvents({
              userID: userCreds.username,
            })
          ),
          tap((notificationEvents) => {
            this.notificationEvents = notificationEvents;
            this.calcTimesSinceCreation();
            this.waiting = false;
          })
        )
        .subscribe()
    );
  }

  onMarkAsRead(i: number): void {
    const notificationEventID = this.notificationEvents[i].NotificationEventID!;
    this.notificationsManagerService
      .updateNotificationEvents({ notificationEventIDs: [notificationEventID] })
      .subscribe(() => this.getNotificationEvents());
  }

  onMarkAllAsRead(): void {
    const notificationEventIDs = this.notificationEvents.map(
      (event) => event.NotificationEventID!
    );
    this.notificationsManagerService
      .updateNotificationEvents({ notificationEventIDs })
      .subscribe(() => this.getNotificationEvents());
  }

  onMarkSelectedAsRead(): void {
    if (this.selection.selected.length !== 0) {
      const notificationEventIDs = this.selection.selected.map(
        (event) => event.NotificationEventID!
      );
      this.notificationsManagerService
        .updateNotificationEvents({ notificationEventIDs })
        .subscribe(() => this.getNotificationEvents());
    }
  }

  calcTimesSinceCreation(): void {
    this.timesSinceNotificationCreations = [];
    const now = new Date().getTime();
    this.timesSinceNotificationCreations = this.notificationEvents.map(
      (event) => {
        const creationDate = new Date(event.CreatedDateTime!).getTime();
        const timeDifference = this.msToTime(now - creationDate);
        return timeDifference;
      }
    );
  }

  msToTime(ms: number): string {
    const seconds = Number((ms / 1000).toFixed(0));
    const minutes = Number((ms / (1000 * 60)).toFixed(0));
    const hours = Number((ms / (1000 * 60 * 60)).toFixed(0));
    const days = Number((ms / (1000 * 60 * 60 * 24)).toFixed(0));
    if (seconds < 60) return seconds + ' secs';
    else if (minutes < 60) return minutes + ' mins';
    else if (hours < 24) return hours + ' hours';
    else return days + ' days';
  }
}
