export const environment = {
  production: false,
};

export const apiHost = 't450bq1lyf.execute-api.us-east-1.amazonaws.com';
export const apiUrl: string =
  'https://t450bq1lyf.execute-api.us-east-1.amazonaws.com/development/api';

export const cognitoConfig = {
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_IZ5jGeI0n',
  aws_user_pools_web_client_id: '2a8vt7f91ecc668ojoff4tm2r6',
  aws_cognito_identity_pool_id:
    'us-east-1:1608b530-f5fa-483b-97d4-4aba6e9563c6',
  API: {
    // TODO: dynamically create these endpoints from given environment
    endpoints: [
      {
        name: 'development-parratus-api',
        endpoint: apiUrl,
      },
    ],
  },
};

export const tinyMceApiKey = 'zcl5if5vwe2ng1rpywiotmtslzf0blhq4de0vmnzqefiycba';
