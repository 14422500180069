import { HttpParams } from '@angular/common/http';
import { QueryOptions } from 'src/app/shared/models/models.index';

export const createParams = (
  queryOptions: Partial<QueryOptions>
): HttpParams => {
  let params = new HttpParams();
  Object.entries(queryOptions).forEach(([key, value]) => {
    if (typeof value === 'string') params = params.append(key, value);
    else params = params.append(key, JSON.stringify(value));
  });
  return params;
};
