import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { tinyMceApiKey } from 'src/environments/environment';

@Component({
  selector: 'app-plain-text-editor',
  templateUrl: './plain-text-editor.component.html',
  styleUrls: ['./plain-text-editor.component.css'],
})
export class PlainTextEditorComponent {
  @Input() form!: FormGroup;
  @Input() initialMessage = '';
  @Input() isQuestionForm!: string;
  tinyMceApiKey = tinyMceApiKey;

  editorOptions = {
    height: 300,
    menubar: false,
    plugins: [
      'lists',
      'advlist',
      'autolink',
      'link',
      'image',
      'charmap',
      'preview',
      'anchor',
      'searchreplace ',
      'visualblocks',
      'code',
      'fullscreen',
      'insertdatetime',
      'media',
      'table',
      'help',
      'wordcount',
    ],
    toolbar: `undo redo | fontselect fontsizeselect |
        bold italic | formatselect | link |
        alignleft aligncenter alignright alignjustify |
        bullist numlist outdent indent | help`,
    init_instance_callback: (editor: any) => {
      if (this.isQuestionForm === 'true') {
        editor.setContent(this.initialMessage);
      }
    },
  };
}
