import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { apiUrl } from 'src/environments/environment';
import { HttpErrorService } from 'src/app/shared/services/http-error.service';

import {
  Question,
  QuestionAttachment,
  QuestionType,
  QueryOptions,
} from 'src/app/shared/models/models.index';
import {
  UserActivityService,
  ResourceType,
} from 'src/app/shared/services/user-activity/user-activity.service';
import { Cacheable, CacheBuster } from 'ts-cacheable';

const cacheBuster$ = new Subject<void>();
const HOUR_TIME = 3600000;
const DAY_TIME = 86400000;
@Injectable({
  providedIn: 'root',
})
export class QuestionsBuilderService {
  constructor(
    private http: HttpClient,
    private httpError: HttpErrorService,
    private userActivityService: UserActivityService
  ) {}

  getQuestionTypes(): Observable<QuestionType[]> {
    return this.http
      .get<any>(`${apiUrl}/question-types`)
      .pipe(catchError((err) => this.httpError.handleError(err)));
  }

  /**
   * @param queryOptions Available options: assessmentID, includedDeleted, organizationID
   * @returns Get request for given resource
   */
  @Cacheable({
    cacheBusterObserver: cacheBuster$,
    maxAge: HOUR_TIME,
    slidingExpiration: true,
  })
  getQuestions(queryOptions?: Partial<QueryOptions>): Observable<Question[]> {
    return this.userActivityService.readResource(ResourceType.QUESTION, {
      queryOptions,
    });
  }

  @Cacheable({
    cacheBusterObserver: cacheBuster$,
    maxAge: HOUR_TIME,
    slidingExpiration: true,
    maxCacheCount: 2,
  })
  getQuestion(id: number): Observable<Question> {
    return this.userActivityService.readResource(ResourceType.QUESTION, {
      resourceId: id,
    });
  }

  downloadQuestionAttachment(
    questionId: number,
    attachmentId: number
  ): Observable<QuestionAttachment> {
    return this.http
      .get<any>(`${apiUrl}/questions/${questionId}/attachments/${attachmentId}`)
      .pipe(catchError((err) => this.httpError.handleError(err)));
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$,
  })
  saveQuestion(question: Question): Observable<any> {
    return this.userActivityService.createResource(
      question,
      ResourceType.QUESTION
    );
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$,
  })
  updateQuestion(id: number, question: Question): Observable<any> {
    return this.userActivityService.updateResource(
      id,
      question,
      ResourceType.QUESTION
    );
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$,
  })
  unarchiveQuestion(id: number, question: Question): Observable<any> {
    return this.userActivityService.updateResource(
      id,
      question,
      ResourceType.QUESTION
    );
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$,
  })
  deleteQuestion(id: number): Observable<any> {
    return this.userActivityService.deleteResource(id, ResourceType.QUESTION);
  }

  @Cacheable({
    maxAge: DAY_TIME,
    slidingExpiration: true,
  })
  getRunnableLanguages(): Observable<any[]> {
    return this.http
      .get<any>(`${apiUrl}/run`)
      .pipe(catchError((err) => this.httpError.handleError(err)));
  }
}
