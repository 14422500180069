import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../../core/core.module';
import { QuestionsFormComponent } from './components/questions-form/questions-form.component';
import { CreateQuestionComponent } from './pages/create-question/create-question.component';
import { QuestionsBuilderRoutingModule } from './questions-builder-routing.module';
import { QuestionsListComponent } from './components/questions-list/questions-list.component';
import { ViewQuestionsComponent } from './pages/view-questions/view-questions.component';
import { QuestionsModalComponent } from './components/questions-modal/questions-modal.component';

@NgModule({
  declarations: [
    QuestionsFormComponent,
    CreateQuestionComponent,
    QuestionsListComponent,
    ViewQuestionsComponent,
    QuestionsModalComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    QuestionsBuilderRoutingModule,
    ReactiveFormsModule,
  ],
  exports: [QuestionsFormComponent],
  providers: [],
})
export class QuestionsBuilderModule {}
