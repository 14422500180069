import { Component } from '@angular/core';
import { Question } from 'src/app/shared/models/models.index';
import { QuestionDialogData } from '../../components/questions-modal/questions-modal.component';
import { SideNavContentAnimation } from 'src/app/shared/components/side-nav/side-nav.animations';
import { SideNavService } from 'src/app/shared/components/side-nav/side-nav.service';

@Component({
  selector: 'app-create-question',
  templateUrl: './create-question.component.html',
  styleUrls: ['./create-question.component.css'],
  animations: [SideNavContentAnimation],
})
export class CreateQuestionComponent {
  formData: QuestionDialogData = {
    question: null as unknown as Question,
    action: 'create',
  };

  constructor(public sidenav: SideNavService) {}
}
