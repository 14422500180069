// https://stackoverflow.com/questions/48479524/how-to-impliment-a-sidenav-navigation-drawer-with-a-mini-variant
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

const COLLAPSED_WIDTH_REM: number = 5;
// make sure this matches the CSS
const EXPANDED_WIDTH_REM: number = 12;

/*
 * SideNavAnimation describes the side-nav component's transition from open to closed.
 */
export const SideNavAnimation = trigger('sideNavTrigger', [
  state(
    'open',
    style({
      width: `${EXPANDED_WIDTH_REM}rem`,
    })
  ),
  state(
    'closed',
    style({
      width: `${COLLAPSED_WIDTH_REM}rem`,
    })
  ),
  transition('open => closed', [animate('0.3s ease-out')]),
  transition('closed => open', [animate('0.3s ease-in')]),
]);

/*
 * SideNavContentAnimation describes how content displayed next to the side-nav component
 *    should respond to the side-nav being open or closed
 *
 * Attach with => [@sideNavContentTrigger]="isOpen ? 'open' : 'closed'"
 * Don't forget to import and add to @Component({ animations: [>>> here <<<] })
 */
export const SideNavContentAnimation = trigger('sideNavContentTrigger', [
  state(
    'open',
    style({
      'margin-left': `${EXPANDED_WIDTH_REM + 1}rem`,
    })
  ),
  state(
    'closed',
    style({
      'margin-left': `${COLLAPSED_WIDTH_REM + 1}rem`,
    })
  ),
  transition('open => closed', [animate('0.3s ease-out')]),
  transition('closed => open', [animate('0.3s ease-in')]),
]);

/*
 *    [@sideNavButtonTrigger]="isOpen ? 'open' : 'closed'"
 */
export const SideNavButtonAnimation = trigger('sideNavButtonTrigger', [
  state(
    'open',
    style({
      width: '8em',
    })
  ),
  state(
    'closed',
    style({
      width: '2em',
    })
  ),
  transition('open => closed', [animate('0.3s ease-out')]),
  transition('closed => open', [animate('0.3s ease-in')]),
]);

export const SideNavArrowAnimation = trigger('sideNavArrowTrigger', [
  state('open', style({ transform: 'rotate(0)' })),
  state('closed', style({ transform: 'rotate(-180deg)' })),
  transition('open => closed', [animate('0.3s ease-out')]),
  transition('closed => open', [animate('0.3s ease-in')]),
]);
