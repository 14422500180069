import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const NOT_AUTHORIZED_EXCEPTION = 'InvalidParameterException';

export enum AuthExceptions {
  InvalidIdentityPoolConfigurationException = 'InvalidIdentityPoolConfigurationException',
  NotAuthorizedException = 'NotAuthorizedException',
  AccessDeniedException = 'AccessDeniedException',
  CredentialsError = 'CredentialsError',
}

/* Wrapper class for the case of NotAuthorizedExceptions so they can be easily detected later down the line */
export class NotAuthorizedError extends Error {
  constructor() {
    super('NotAuthorizedException');
    Object.setPrototypeOf(this, NotAuthorizedError.prototype);
  }
}

/* Used to specify if an error should be rethrown or not */
export const RETURN_EMPTY = true;

const isAuthException = (code: string) => {
  return (
    code === AuthExceptions.NotAuthorizedException ||
    code === AuthExceptions.InvalidIdentityPoolConfigurationException ||
    code === AuthExceptions.AccessDeniedException ||
    code === AuthExceptions.CredentialsError
  );
};
/* Detects NotAuthorizedException and handles errors */
export const catchNotAuthorized =
  (returnEmpty = false) =>
  (source: Observable<any>) => {
    /* Detects NotAuthorizedException */
    const isNotAuthorized = (err: any) => {
      const exception = isAuthException(err.code);
      return exception;
    };

    /* Returns NotAuthorizedError or rethrows original error */
    const handleError = (err: any) =>
      isNotAuthorized(err) ? new NotAuthorizedError() : err;

    return source.pipe(catchError((err) => throwError(handleError(err))));
  };
