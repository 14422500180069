<amplify-auth-container>
  <amplify-authenticator
    [loginMechanisms]="['email']"
    [hideSignUp]="true"
    [formFields]="formFields"
  >
    <ng-template amplifySlot="sign-in-header">
      <h2 class="amplify-heading">Sign in to your account</h2>
    </ng-template>

    <ng-template amplifySlot="sign-in-footer">
      <div class="signin-footer">
        <span>
          Forgot your password?
          <button
            (click)="authenticator.toResetPassword()"
            class="amplify-button amplify-field-group__control"
            data-fullwidth="false"
            data-size="small"
            data-variation="link"
            type="button"
            style="font-weight: normal"
          >
            Reset Password
          </button>
        </span>

        <span>
          No account?
          <button
            (click)="toSignUp()"
            class="amplify-button amplify-field-group__control"
            data-fullwidth="false"
            data-size="small"
            data-variation="link"
            type="button"
            style="font-weight: normal"
          >
            Create New Account
          </button>
        </span>
      </div>
    </ng-template>

    <ng-template amplifySlot="sign-up-footer">
      <div style="text-align: center">
        <button
          (click)="authenticator.toSignIn()"
          class="amplify-button amplify-field-group__control"
          data-fullwidth="false"
          data-size="small"
          data-variation="link"
          type="button"
          style="font-weight: normal"
        >
          Back to Sign In
        </button>
      </div>
    </ng-template>

    <ng-template amplifySlot="footer">
      <div style="padding: var(--amplify-space-large); text-align: center">
        <p class="amplify-text" style="color: var(--amplify-colors-neutral-80)">
          © All Rights Reserved
        </p>
      </div>
    </ng-template>
  </amplify-authenticator>
</amplify-auth-container>
