<mat-toolbar [ngStyle]="{ display: display }">
  <span class="inner-header">Antage Interview</span>

  <div class="right inner-wrapper">
    <ng-container *ngIf="userIsLoggedIn">
      <button
        mat-icon-button
        [matMenuTriggerFor]="usersOptions"
        class="usersIconContainer"
        #tooltip="matTooltip"
        matTooltip="Manage Users"
      >
        <fa-icon [icon]="usersIcon"></fa-icon>
      </button>

      <mat-menu #usersOptions="matMenu">
        <button mat-menu-item [routerLink]="['/users']">Manage Users</button>
        <button mat-menu-item [routerLink]="['/roles']">Manage Roles</button>
        <button mat-menu-item [routerLink]="['/rotations']">
          Manage Rotations
        </button>
      </mat-menu>

      <button
        mat-icon-button
        [matMenuTriggerFor]="settingsPage"
        class="settingsIconContainer"
        #tooltip="matTooltip"
        matTooltip="Settings"
      >
        <fa-icon [icon]="settingsIcon"></fa-icon>
      </button>

      <mat-menu #settingsPage="matMenu">
        <button mat-menu-item [routerLink]="['/account/settings']">
          My Account
        </button>
        <button mat-menu-item [routerLink]="['/notifications/options']">
          Manage notifications
        </button>
        <button mat-menu-item [routerLink]="['/account/change-password']">
          Change password
        </button>
      </mat-menu>

      <button
        mat-icon-button
        (click)="onShowNotifications()"
        class="notificationsIconContainer"
        #tooltip="matTooltip"
        matTooltip="Notifications"
      >
        <fa-icon [icon]="notificationsIcon"></fa-icon>
        <span *ngIf="displayedNotificationCount" class="notificationsCount">
          {{ displayedNotificationCount }}
        </span>
      </button>

      <mat-menu #notifications="matMenu">
        <button mat-menu-item>Notifications</button>
      </mat-menu>

      <button
        mat-icon-button
        [matMenuTriggerFor]="contactSupport"
        #tooltip="matTooltip"
        matTooltip="Help"
      >
        <fa-icon [icon]="supportIcon"></fa-icon>
      </button>

      <mat-menu #contactSupport="matMenu">
        <div id="version">v{{ version }}</div>
        <button mat-menu-item [routerLink]="['/account/contact-support']">
          Contact Support
        </button>
      </mat-menu>

      <button
        mat-icon-button
        (click)="signOut()"
        [routerLink]="['/login']"
        #tooltip="matTooltip"
        matTooltip="Sign Out"
      >
        <fa-icon [icon]="signOutIcon"></fa-icon>
      </button>
    </ng-container>
  </div>
</mat-toolbar>
