import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Question } from 'src/app/shared/models/models.index';
import { QuestionsBuilderService } from '../../questions-builder.service';
import { QuestionsModalComponent } from '../questions-modal/questions-modal.component';
import { QuestionDialogData } from '../questions-modal/questions-modal.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-questions-list',
  templateUrl: './questions-list.component.html',
  styleUrls: ['./questions-list.component.css'],
})
export class QuestionsListComponent implements OnInit, OnDestroy {
  displayedQuestionData: string[] = [
    'QuestionID',
    'QuestionName',
    'QuestionDescription',
    'QuestionTypeName',
    'QuestionArchive',
    'Actions',
  ];
  subscription: Subscription = new Subscription();
  waiting: boolean = false;
  currentQuestionsList = false;

  public questions = new MatTableDataSource<any>();

  @ViewChild(MatPaginator, { static: false }) set contents(
    paginator: MatPaginator
  ) {
    this.questions.paginator = paginator;
  }
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.questions.sort = sort;
  }

  constructor(
    private questionsBuilderService: QuestionsBuilderService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.waiting = true;
    this.getQuestions();
    // Since we are getting the question type from accessing the column data via the string QuestionType.QuestionTypeName,
    // angular is looking for QuestionType.name instead of QuestionType.QuestionTypeName.name
    // This will allow us to sort by Type
    this.questions.sortingDataAccessor = (item, property) => {
      if (property === 'QuestionTypeName') {
        return item.QuestionType.QuestionTypeName.toLowerCase();
      } else if (property === 'QuestionArchive') {
        return item.DeletedDateTime
          ? `2${item?.QuestionID}`
          : `1${item?.QuestionID}`;
      } else {
        if (typeof item[property] === 'string')
          return item[property].toLowerCase();
        else return item[property];
      }
    };

    // Certain fields are not default filterable due to how they are formatted. This sets a filterPredicate baseline for all fields
    this.questions.filterPredicate = (data: any, filter) => {
      const dataStr = JSON.stringify(data).toLowerCase();
      if (filter === 'current') {
        return (
          data.DeletedDateTime == null ||
          data.QuestionName.toLowerCase().includes(filter) ||
          data.QuestionDescription.toLowerCase().includes(filter)
        );
      } else if (filter === 'archived') {
        return (
          data.DeletedDateTime != null ||
          data.QuestionName.toLowerCase().includes(filter) ||
          data.QuestionDescription.toLowerCase().includes(filter)
        );
      }
      return dataStr.indexOf(filter) != -1;
    };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onAction(
    question: Question,
    action: 'create' | 'view' | 'edit' | 'delete' | 'undelete'
  ): void {
    const data: QuestionDialogData = { question, action };
    const dialogRef = this.dialog.open(QuestionsModalComponent, { data });
    dialogRef.afterClosed().subscribe(() => this.getQuestions());
  }

  getQuestions(): void {
    this.subscription.add(
      this.questionsBuilderService
        .getQuestions({ includeDeleted: true })
        .subscribe((questions) => {
          this.questions.data = questions;
          this.waiting = false;
        })
    );
  }

  getCurrentQuestions(event: any): void {
    this.currentQuestionsList = event.checked;
    this.applyFilter(event);
  }

  public applyFilter = (filterValue: any) => {
    if (filterValue.checked) {
      filterValue = 'current';
    } else {
      filterValue = filterValue.target
        ? (filterValue.target as HTMLInputElement).value
        : '';
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    }
    this.questions.filter = filterValue;
  };
}
